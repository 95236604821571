<template lang="pug">
.docs.view
    section
        .section-content-wrapper
            .title
                h2 Документация
                h1 API
            .content
                .sections
                    .section
                        h3 Endpoint
                        div https://opt-in.ru/api/shared
                    .section
                        h3 Методы
                        nav
                            a(href="#tinyurl") # Получение ссылок для авторизации абонентов
                            a(v-if="false" href="#subscription") # Информация о подписке
                    .section#tinyurl_task
                        h3 Получение ссылок для авторизации абонентов
                        .subtitle Поставить задачу на основе файла со списком номеров телефонов.
                        h2 POST /tinyurl/task
                        b
                            ul
                                li -H Subscriber: {token}
                                li -H Content-Type: multipart/form-data
                                li -H Accept: application/json
                        .input
                            .table
                                .th
                                    .td Свойство
                                    .td Тип
                                    .td Описание
                                .tr
                                    .td
                                        b sender
                                    .td string
                                    .td Название отправителя
                                .tr
                                    .td
                                        b batch
                                    .td file
                                    .td Файл в формате CSV со списком номеров телефонов построчно
                        .output
                            h3 HTTP 200
                            pre
                                |{
                                |    "uid": "1289d213-343a-4457-ac34-9456f5657b42",
                                |    "status": "awaiting",
                                |}
                            .table
                                .th
                                    .td Свойство
                                    .td Тип
                                    .td Описание
                                .tr
                                    .td
                                        b uid
                                    .td string
                                    .td Уникальный id задачи
                                .tr
                                    .td
                                        b status
                                    .td string
                                    .td Статус задачи
                        .output
                            h3 HTTP 4xx, 5xx
                            pre
                                |{
                                |    "errors": ["tynyurl.errors.batch_create.failed"],
                                |}
                            .table
                                .th
                                    .td Свойство
                                    .td Тип
                                    .td Описание
                                .tr
                                    .td
                                        b errors
                                    .td array
                                    .td Список алиасов ошибок
                    .section#subscription(v-if="false")
                        h1 Информация о подписке
                        h2 POST /subscription
                        b
                            ul
                                li -H Authorization: Bearer {token}
                                li -H Content-Type: application/json
                                li -H Accept: application/json
                        .input
                            pre
                                | {
                                |   "channel_alias": "sms",
                                |   "id": 1,
                                |   "phone": 79660000000,
                                |   "sender_name": "Rostelecom"
                                | }
                            .table
                                .th
                                    .td Свойство
                                    .td Тип
                                    .td Описание
                                .tr
                                    .td
                                        b channel_alias
                                    .td string
                                    .td Идентификатор канала получения сообщений
                                .tr
                                    .td
                                        b id
                                    .td int
                                    .td Уникальный идентификатор подписки
                                .tr
                                    .td
                                        b phone
                                    .td int
                                    .td Номер телефона подписчика
                                .tr
                                    .td
                                        b sender_name
                                    .td string
                                    .td Название отправителя
                        .output
                            h4 HTTP 200
                            pre
                                |{
                                |    "id": 1,
                                |    "channel_alias": "sms",
                                |    "phone": 79660000000,
                                |    "sender_name": "Rostelecom",
                                |    "subscribed": false,
                                |    "created_at": "2021-02-02T00:00:00.000000Z",
                                |    "updated_at": "2021-02-02T00:00:00.000000Z"
                                |}
                            .table
                                .th
                                    .td Свойство
                                    .td Тип
                                    .td Описание
                                .tr
                                    .td
                                        b subscribed
                                    .td bool
                                    .td Признак согласия на получение сообщений
                                .tr
                                    .td
                                        b created_at
                                    .td string
                                    .td Дата первого зафиксированного волеизъявления абонента
                                .tr
                                    .td
                                        b updated_at
                                    .td string
                                    .td Дата обновления информации о подписке
                        .output
                            h4 HTTP 404
                            pre
                                |{
                                |    "errors": [
                                |        "subscription.errors.not_found"
                                |    ]
                                |}

</template>

<script>
    export default {
        metaInfo() {
            return {
                title: this.title
            }
        },
        props: [
            'id'
        ],
        data() {
            return {
            }
        },
        computed: {
            title(){
                return `Документация`
            },
        },
    }
</script>

<style lang="scss" scoped>
.docs{
    background: $color--background;
    color: $color--black;

    .content{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: $padding-global;

        nav{
            a, b{
                display: block;
                padding: .5em 0;
            }
            b{
                padding-top: 1em;
            }
            span{
                color: $color--primary;
            }
        }

        .sections{
            .section{
                padding-top: $padding-global;

                .subtitle{
                    padding-bottom: $padding-global / 2;
                }
                .input, .output{
                    .table{
                        margin-top: $padding-global / 2;
                    }
                }
                .output{
                    padding-top: $padding-global / 2;
                }
                &:first-child{
                    padding-top: 0;
                }
            }
        }
    }
}
</style>
